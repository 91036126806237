const getters = {
    getConfig: state => {
        return state.config;
    },
    getCompanyName: state => {
        return state.name
    },
    getPages: state => {

        return state.pages ? state.pages : null
    },
    getCompanyInsightLayout: state => {
        return state.insightsLayout ? JSON.parse(state.insightsLayout) : []
    },
    getActivities: state => {
        return state.activities ? state.activities : [];
    },
    getActivitiesLowerCased: state => {
        return state.activities ? _.map(state.activities, activity => {
            return activity.toLowerCase();
        }) : [];
    },
    getDownTimeConfig: state => {
        return state.config && state.config.downTime ? state.config.downTime : null;
    },
    getCompanyDepartments: state => {
        return state.departments ? state.departments : [];
    },
    getLineSpeedConfig: state => {
        try {
            return state.config.startStop.ui.lineSpeed
        } catch (e) {
            return false
        }
    },
    getOperatorInterfaceEnabledBooleanByTileId: (state, getters, rootState, rootGetters) => (tileId) => {
        const defaultTiles = ['lineSpeedLastMinute', 'lineSpeedLastQuarter', 'speedLineWithDefects', 'timeLeft'];
        let selectedWorkflow = rootGetters['users/getSelectedWorkflow']
        let workflowConfig = rootGetters['workflowJobConfigs/getWorkflowJobOperatorInterfaceConfig'](selectedWorkflow)
        if (workflowConfig) {
        return  !!workflowConfig.tiles[tileId]
        } else if (state.config && state.config.operatorInterface && state.config.operatorInterface.tiles) {
            return !!state.config.operatorInterface.tiles[tileId];
        } else {
            return defaultTiles.indexOf(tileId) > -1;
        }
    },
    getOperaratorInterfaceConfig: (state, getters, rootState, rootGetters) => {
        let config = {
            bar: state.config.operatorInterface && state.config.operatorInterface.progress.bar,
            chart: state.config.operatorInterface && state.config.operatorInterface.progress.chart
        }

        let selectedWorkflow = rootGetters['users/getSelectedWorkflow']
        let workflowConfig = rootGetters['workflowJobConfigs/getWorkflowJobOperatorInterfaceConfig'](selectedWorkflow)
        if (workflowConfig) {
            config = workflowConfig.progress
        }
        if (!config.bar && !config.chart) {
            config.bar = true
        }
        return config
    },
    getProductNumberKey: state => {
        let productNumberKey = state.config.oee && state.config.oee.productNumberKey
        if (!productNumberKey) {
            console.log('No config key set for productnumber, default to productNumber')
        }
        return productNumberKey ? productNumberKey : 'productNumber'
    },
    getAvailabilityUnit: state => {
        let unit = state.config.oee && state.config.oee.units && state.config.oee.units.availability
        if (!unit) {
            console.log('No config key set for unit availability, default to \'h\'');
        }
        return unit ? unit : 'h'
    },
    getBdrUnit: state => {
        let unit = state.config.oee && state.config.oee.units && state.config.oee.units.bdr
        if (!unit) {
            console.log('No config key set for unit bdr, default to \'m\'');
        }
        return unit ? unit : 'hour'
    },
    getNpcUnit: state => {
        let unit = state.config.oee && state.config.oee.units && state.config.oee.units.npc
        if (!unit) {
            console.log('No config key set for unit npc, default to \'h\'');
        }
        return unit ? unit : 'h'
    },
    getPerformanceUnit: state => {
        let unit = state.config.oee && state.config.oee.units && state.config.oee.units.performance
        if (!unit) {
            console.log('No config key set for unit performance, default to \'h\'');
        }
        return unit ? unit : 'h'
    },
    getBdrTimeFrame: state => {
        let bdrTimeFrame = state.config.oee && state.config.oee.bdrTimeFrame
        if (!bdrTimeFrame) {
            console.log('No config key set for bdrTimeFrame, default to 15')
        }
        return bdrTimeFrame ? bdrTimeFrame : 15
    },
    getNpcKey: state => {
        let npcKey = state.config.oee && state.config.oee.npcKey
        if (!npcKey) {
            console.log('No config key set for bdrTimeFrame, default to npc')
        }
        return npcKey ? npcKey : 'npc'
    },
    getLineSpeedWithDefects: (state,getters,rootState,rootGetters) => {
        try {
            let selectedWorkflow = rootGetters['users/getSelectedWorkflow']
            let workflowConfig = rootGetters['workflowJobConfigs/getWorkflowJobOperatorInterfaceConfig'](selectedWorkflow)
            if (workflowConfig) {
               return workflowConfig.ui.lineSpeedWithDefects
            }
            return state.config.startStop.ui.lineSpeedWithDefects
        } catch (e) {
            return false
        }
    },
    getScannerConfig: state => {
        let scannerConfig = state.config.barcodeScanner
        if (!scannerConfig) {
            return {
                job: {
                    key: "orderNumber"
                }
            }
        }
        return scannerConfig
    },
    getOeeConfig: state => {
        let oee = _.clone(state.config.oee)
        if (!oee.cycleTime) {
            oee.cycleTime = {
                timeFrame: {
                    value: 5,
                    unit: 'minute'
                },
                timePerProduct: 'second'
            }
        }
        if (!oee.timeFrame) {
            oee.timeFrame = {
                shiftView: true,
                time: {
                    start: "00:00",
                    end: "23:59:59"
                }
            }
        }

        // set default

        return oee
    },
    getOEETimeFrameView: (state, getters, rootState, rootGetters) => {
        let activeShift = rootGetters['activeShifts/getActiveShift']
        let config = getters.getOeeConfig
        let datetime = {
            from: moment(activeShift.shiftStartDatetime).format('HH:mm:ss'),
            to: moment(activeShift.shiftEndDatetime).format('HH:mm:ss')
        }
        if (!config.timeFrame.shiftView) {
            datetime = {from: config.timeFrame.time.start, to: config.timeFrame.time.end} // <----- comes from config shift or datetime
        }

        // DATETIME FROM CONFIG


        let startDateTime = moment(datetime.from, 'HH:mm:ss');
        let endDateTime = rootGetters['getTime']

        if (moment().isBefore(startDateTime)) {
            startDateTime = startDateTime.subtract(1, "day")
        }

        return {startDateTime, endDateTime}
    }
};

export default getters;