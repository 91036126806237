<template>
  <span class="small text-danger">
   {{notice}}
  </span>
</template>

<script>
  // @vuese
  // @group General/ui
  // is required notice
  export default {
    name: 'RequiredNotice',
    props: {
      //@vuese
      // form  error message, returns form.required from translations if no notice is passed
      notice:{
        required:false,
        type:String,
        // The default value is: `this.$t('form.required')`
        default:function(){
          return this.$t('form.required')
        }
      }
    },

  };
</script>
