import translation from './../../i18n';

const getters = {
    getJobs: (state) => {
        return _.toArray(state.jobs);
    },

    getJob: (state) => (id) => {
        return state.jobs[id];
    },
    getJobIds: (state) => {
        return _.map(state.jobs, j => j.id);
    },
    getJobDetail: (state, getters, rootState, rootGetters) => (jobId) => {
        let job = getters.getJob(jobId);
        // @key is the key of the job object
        // translations are not possible
        //  @label is the label being displayed in pmt
        // @type indicates how it should be formatted, this can be extended later on
        // example json    {"key": "activity", "label": "Activity", "type": "string"},
        let baseDetail = [
            {key: 'activity', label: 'Activity', type: 'string'},
            {key: 'productNumber', label: 'Product number', type: 'string'},
            {key: 'productDescription', label: 'Product Description', type: 'string'},
            {key: 'department', label: 'Department', type: 'string'},
            {key: 'amount', label: 'Amount', type: 'integer'},
            {key: 'amountProduced', label: 'Amount produced', type: 'integer'},
            {key: 'plannedDate', label: 'Planned date', type: 'date', formatOut: 'DD-MM-YY'} // no format in needed as this is a firebase timestamp (keep in mind the conversion done by vuex easy firestore
        ]
        let companyConfig = rootGetters['company/getConfig']
        try {
            // if no company config is present for the job details, returns null so default is used in pmt
            let detailFromConfig = companyConfig.jobs && companyConfig.jobs.details ? companyConfig.jobs.details : []
            if (!detailFromConfig.length) {
                return null
            }
            // let mnappedValues =  _.concat(_.map(baseDetail,'key'),_.map(detailFromConfig,'key'))
            let detailsMerged = _.concat(baseDetail, detailFromConfig)

            let jobDetailData = {}
            _.each(detailsMerged, detail => {
                let formattedValue
                if (job[detail.key]) {
                    formattedValue = job[detail.key]
                    if (detail.type == 'string') {
                        formattedValue = job[detail.key].toString()
                    }
                    if (detail.type == 'integer') {
                        formattedValue = parseInt(job[detail.key])
                    }
                    if (detail.type == 'float') {
                        formattedValue = parseFloat(job[detail.key])
                    }
                    if (detail.type == 'date') {
                        formattedValue = moment(job[detail.key].toDate()).format(detail.formatOut)
                    }

                }
                jobDetailData[detail.key] = {
                    label: detail.label,
                    value: formattedValue,
                }
            })
            return jobDetailData

        } catch (e) {
            return null
        }


    },
    getJobsWithWorkflowstates: (state, getters, rootState, rootGetters) => {
        let jobs = Object.values(state.jobs);
        const companyActivities = rootGetters['company/getActivities'];
        const selectedWorkflow = rootGetters['workflows/getWorkflow'](rootGetters['users/getSelectedWorkflow']);
        const workflowActivities = selectedWorkflow && selectedWorkflow.activities && selectedWorkflow.activities.length >= 1 ? selectedWorkflow.activities : companyActivities;

        jobs = _.filter(jobs, job => {
            // This filters jobs based on the allowedWorkflowIds
            // If not specified or no selected workflow, returns all
            // NOTE: This can cause older orders to not show up if it IS allowed on any workflow and a new one is added later
            let isAllowedOnWorkflow = (!selectedWorkflow || !job.allowedWorkflowIds || job.allowedWorkflowIds.length < 1) || _.includes(job.allowedWorkflowIds, selectedWorkflow.id)


            let departmentsExist = !!job.department
            let userHasAccesToDepartment = rootGetters['userData/getActiveDepartment']

            let departmentAccess
            if (departmentsExist) {
                departmentAccess = job.department === userHasAccesToDepartment
            } else {
                departmentAccess = true
            }
            return _.includes(workflowActivities, job.activity) && departmentAccess && isAllowedOnWorkflow;
        });
        let jobLength = jobs.length;
        for (let i = 0; i < jobLength; i++) {
            let job = jobs[i];
            job.workflowStates = getters.getJobWorkflowStates(job.id);
            // We also add the state of the current workflow, for easy purproses
            let currentWorkflowState = job.workflowStates.find(workflow => workflow.id == rootGetters['users/getSelectedWorkflow']);
            if (currentWorkflowState) {
                if (job.status !== 'finished') {
                    job.status = currentWorkflowState.status;
                }
            }
        }
        return jobs;
        // old code. keep for performance
        // return getters.getJobs.map(job => {
        //     job.workflowStates = getters.getJobWorkflowStates(job.id);
        //     // We also add the state of the current workflow, for easy purproses
        //
        //     let currentWorkflowState = job.workflowStates.find(workflow => workflow.id == rootGetters['users/getSelectedWorkflow']);
        //     if (currentWorkflowState) {
        //         if (job.status !== 'finished') {
        //             job.status = currentWorkflowState.status;
        //         }
        //     }
        //     return job;
        // });
    },
    getJobWithWorkflowStates: (state, getters, rootState, rootGetters) => (jobId) => {
        let job = getters.getJob(jobId);

        // All Orderline states...
        job.workflowStates = getters.getJobWorkflowStates(jobId);
        // We also add the state of the current workflow, for easy purproses
        let currentWorkflowState = job.workflowStates.find(workflow => workflow.id == rootGetters['users/getSelectedWorkflow']);
        if (currentWorkflowState) {
            if (job.status !== 'finished') {
                job.status = currentWorkflowState.status;
            }
        }
        return job;

    },
    getJobProducts: (state, getters) => {
        return _.uniq(_.map(getters.getJobs, 'product'));
    },
    getJobWorkflows: (state, getters) => {
        return _.uniq(_.compact(_.map(getters.getJobs, 'workflowId')));
    },
    getJobWorkflowStates: (state, getters, rootState, rootGetters) => (jobId) => {
        let productionRuns = rootGetters['productionRuns/getProductionRunsOfJob'](jobId);
        let workflows = rootGetters['workflows/getWorkflows'];
        let job = getters.getJob(jobId);
        let productionRunsOfJob = productionRuns.filter(productionRun => productionRun.jobId == jobId);

        return workflows.map(function (workflow) {
            let status;
            let runningProductionRunOfWorkflow;
            if (productionRunsOfJob.length > 0) {
                // Let's check: if the workflow has an productionRun for this orderline without a productionEnd prop, then its still running
                // So we need to be able to close it.
                // If not: then lets check; if there are still units that need to produce: make the state 'stopped'
                // If we reached the target: then its finished.
                runningProductionRunOfWorkflow = productionRunsOfJob.find(productionRun => productionRun.productionEnd == undefined && productionRun.workflowId == workflow.id);
                if (!job) {
                    status = 'running'
                } else {
                    status = runningProductionRunOfWorkflow != undefined
                        ? 'running' : rootGetters['productionRuns/getProducedAmountOfOrderId'](jobId) >= job.amount ? 'finished' : 'stopped';
                }
            }
            return {
                id: workflow.id,
                name: workflow.name,
                status: status,
                runningProductionRunOfWorkflow: runningProductionRunOfWorkflow
            };
        });
    },
    getRunningJobsOfWorkflow: (state, getters, rootState, rootGetters) => (workflowId) => {
        return rootGetters['productionRuns/getProductionRunsOfWorkflow'](workflowId).filter(productionRun => productionRun.productionEnd == undefined);
    },
    getJobTarget: (state) => (id) => {
        return state.jobs[id].target;
    },
    getFormattedNPC: (state) => (job) => {
        let npc = job.npcMeta
        return npc.amount + ' / ' + translation.tc('ui.date.' + npc.timeUnit)
    },
    getJobTotalDownTime: (state, getters, rootState, rootGetters) => (id) => {
        let downTimes = rootGetters['downTimes/getDownTimesForJob'](id);
        let diff;
        let totalDownTimeInMs = _.reduce(downTimes, function (sum, downTime) {
            if (!downTime.downTimeEnded) {
                diff = rootState.time.diff(downTime.downTimeIndicator);
            } else {
                diff = moment(downTime.downTimeEnded).diff(downTime.downTimeIndicator);
            }
            return sum + diff;
        }, 0);


        return totalDownTimeInMs;
    },
    getJobTotalExplainedLogs: (state, getters, rootState, rootGetters) => (id) => {
        let jobLogs = getters.getJob(id).logs
        if (jobLogs) {
            let totalTime = _.reduce(jobLogs, function (sum, n) {
                return sum + (n.amount * n.timeInMs);
            }, 0);

            return totalTime;
        }
        return 0
    },
    getJobActivities: (state, getters) => {
        let activity = getters.getJobs.map(job => {
            return job.activity;
        });
        return _.uniq(activity);
    },
    getStartStopButton: (state, getters, rootState, rootGetters) => (jobId) => {
        let startStopConfig = rootGetters['company/getConfig'].startStop
        if (startStopConfig.ui && !startStopConfig.ui.button) {
            return ''
        }
        let job = getters.getJobWithWorkflowStates(jobId);
        if (job.status === 'finished' || rootGetters['productionRuns/getProducedAmountOfJobId'](job.id) >= job.amount || !rootGetters['users/getSelectedWorkflow']) {
            return '';
        } else if (job.status === 'running') {
            return '<button class="btn btn-danger get-data"><i class="fas fa-stop  get-data"></i> ' + translation.t('jobs.table.stop') + '</button>';
        } else {
            return '<button class="btn btn-primary get-data"><i class="fad fa-play  get-data"></i>' + translation.t('jobs.table.start') + '</button>';
        }
    },
    //@vuese
    // Keeps track of how far a job has progressed based on amounts
    getJobProgress: (state, getters) => (jobId) => {
        let job = getters.getJob(jobId);
        let amount = job.amount;
        let amountProduced = job.amountProduced ? job.amountProduced : 0;
        let percentage = amountProduced / amount * 100;
        return {amount, amountProduced, percentage};
    },
    getRunningJobProgress: (state, getters, rootState, rootGetters) => (runningJobs) => {
        if (!runningJobs || runningJobs.length === 0) {
            return {};
        }
        let runningJob = runningJobs[0];
        let time = rootGetters['getTime'].clone();
        let job = getters.getJob(runningJob.jobId);
        job = job ? job : runningJob.job;
        let run = runningJob;

        let produced = job.amountProduced ? job.amountProduced : 0 + run.amountProduced ? run.amountProduced : 0;
        const target = job.target;
        const startTime = runningJob.productionStart;
        let difference = job.timeSpent ? (job.timeSpent + time.diff(startTime)) : time.diff(startTime);

        difference -= job.downTimeTotalPlanned ? job.downTimeTotalPlanned : 0;
        let timeLeftInMs = target - difference < 0 ? 0 : target - difference;

        return {timeLeftInMs, produced}
    }

};
export default getters;