// import router from 'vue-router'

const actions = {
  getMyProfile: ({commit}, ApiClient) => {
    return ApiClient.User.getMe().then(res => {
      if(res.status === 200) {
        var user = checkUserRole(res.data);
        commit('setMe', user)
      }
      return user
    }).catch((err) => {
      return err
    });

  },
  getUser: (ID, ApiClient) => {
    ApiClient.User.getUser(ID).then(res => {
      if(res.status === 200)
        return res
    }).catch((err) => {
      return err
    });

  },
  getUsersFromApi: ({commit}, ApiClient) => {
    ApiClient.User.getUsers().then(res => {
      if(res.status === 200) {
        commit('setUsers', res.data)
      }
    }).catch((err) => {
      return err
    });
  }

};

export default actions

function checkUserRole(user) {

  if(user.type === 'user') {
    user.user = true
  }
  if(user.type === 'admin') {
    user.user = true, user.admin = true
  }
  if(user.type === 'root') {
    user.user = true, user.admin = true, user.root = true
  }

  return user
}