import Vue from 'vue';
import Vuex from 'vuex';

import createPersistedState from 'vuex-persistedstate';

import users from './store/users/user';
import company from './store/company/company';
import trackAndTrace from './store/trackAndTrace/trackAndTrace';
import jobs from './store/jobs/jobs';

import VuexEasyFirestore from 'vuex-easy-firestore';
import i18n from "./i18n";

const easyFirestore = VuexEasyFirestore(
    [
        company,
        trackAndTrace,
        jobs
    ], {
        logging: process.env.NODE_ENV !== 'production',
        FirebaseDependency: Vue.prototype.$FBClient
    }
);
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: false,
        loginLoading:false,
        oAuthToken: null,
        time: null,
        logoutDestination: { routerName: 'login', params: {} },
        stopJobOnLogOut: false,
        locale: 'nl',
        companyId: null
    },
    getters: {
        getLoginLoading: state => state.loginLoading,
        getLoadingScreen: state => state.loading,
        getShowMenu: state => state.showMenu,
        getLocale: state => state.locale,
        getCompanyId: state => state.companyId,

        // getToken: state => state.oAuthToken,
        getLogoutDestination: (state) => {
            return state.logoutDestination;
        },
        getStopJobOnLogOut: state => state.stopJobOnLogOut,
        getTime: state => state.time,
        // getAPICompanyID: (state) => {
        //     if (state.root.MockCompanyID > 0) {
        //         return state.root.MockCompanyID;
        //     } else {
        //         return state.users.me.company.id;
        //     }
        // },
        getUserData: (state) => {
            return {
                id: state.users.me.id,
                name: state.users.me.displayName ? state.users.me.displayName : null,
                email: state.users.me.email
            };
        }
    },
    mutations: {
        setLoginLoading(state,payload){
            state.logginLoading = payload
        },
        setTime(state, payload) {
            state.time = payload;
        },
        setLocale(state, payload) {
            state.locale = payload;
        },
        setCompanyId(state, payload) {
            state.companyId = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setLogoutDestination(state, payload) {
            state.logoutDestination = payload
        },
        setStopJobOnLogOut(state, payload) {
            state.stopJobOnLogOut = payload
        }
        // setOauthToken(state, payload) {
        //     state.oAuthToken = payload;
        // }
    },
    actions: {
        async closeApp({dispatch, commit}) {
            // commit('users/selectedWorkflow', null);
            localStorage.clear();

            console.log('Closing connections now')
            return Promise.all([
                dispatch('jobs/closeDBChannel', {clearModule: true}),
                dispatch('company/closeDBChannel', {clearModule: true}),
                dispatch('trackAndTrace/closeDBChannel', {clearModule: true})
            ]).then(r => {
                console.log('closed all connections')
                dispatch('userData/closeDBChannel')

            }).catch(e => {
                console.log(e)
            });
        },
        getClaims({commit, getters, rootGetters}) {

            return Vue.prototype.$FirebaseAuth.getUserClaims().then(res => {
                commit('users/setClaims', res);
                return res;
            });
        },

        async filterJobs({dispatch, state, commit, getters, rootGetters}, payload) {
            // let userClaims = await Vue.prototype.$FirebaseAuth.getUserClaims();
            let userClaims = rootGetters['users/getClaims']
            let dateRangeFilter = payload.customFilters[0].options.range;
            // let activityFilter = payload.customFilters[1].options.values;
            let statusFilter = payload.customFilters[2].options.values;
            let dateFilterType = payload.customFilters[0].keyToFilter;
            let where = [
                ['companyId', '==', userClaims.companyId],
                [dateFilterType, '>=', dateRangeFilter.min],
                [dateFilterType, '<=', dateRangeFilter.max]
            ];

            if (statusFilter == 'finished') {
                where.push(['status', '==', 'finished']);
            }
            // if(activityFilter.length) {
            //   _.each(activityFilter, filter => {
            //     let filt = ['activity', '==', filter];
            //     where.push(filt);
            //   });
            // }
            await dispatch('jobs/closeDBChannel', {clearModule: true});
            try {
                return dispatch('jobs/openDBChannel', {where});
            } catch (e) {
                console.error('jobs', e);
            }

            return true;
        },
        async initApp({dispatch, state, commit, getters, rootGetters}) {
            return new Promise((resolve) => {
                let user = rootGetters['userData/getMe'];
                console.log("user", user)
                dispatch('getClaims').then(async res => {

                    let userClaims = res;
                    let where = [
                        ['companyId', '==', userClaims.companyId]
                    ];

                    await dispatch('company/setPathVars', {companyId: userClaims.companyId}).then(() => {
                            dispatch('company/openDBChannel');
                        }
                    );

                    await dispatch('trackAndTrace/setPathVars', {companyId: userClaims.companyId}).then(() => {
                            dispatch('trackAndTrace/openDBChannel');
                        }
                    );

                    Promise.all([

                    ]).then(() => {
                        const routeLocaleParam = this.$route.query.lang;
                        console.log("route locale", routeLocaleParam);
                        i18n.locale = routeLocaleParam ? routeLocaleParam : rootGetters['userData/getLocale'];
                        resolve();
                    }).catch(e => {
                        console.error(e);
                        resolve();
                    });
                }).catch(e => {
                    console.error(e);
                    resolve();
                });
            })
        }

    },
    modules: {
        users
    },
    plugins: [
        // logrocketPlugin,
        easyFirestore,
        createPersistedState(
            {paths: ['users.selectedWorkflow']}
        )],
    strict: false
});