const state = {
  me: null,
  selectedWorkflow: null,
  claims:null,
  orderlineWorkflowProduct: null,
  jobRunnning: {
    id: null,
    orderNumber: null
  }
}

export default state