import Vue from 'vue';
import moment from 'moment/moment';


const filters = [
    Vue.filter('typeFormat', function (value) {
        if (typeof value == 'object' && Object.getPrototypeOf(value).hasOwnProperty('toDate')) {
            let format = 'DD/MM/YY';
            return moment(value.toDate()).utc().format(format);
        } else {
            if (typeof value == 'number') {
                return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            }
            return value;
        }
    }),
    Vue.filter('formatNumber', function(value) {
        return value.toString().replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    }),
    Vue.filter('round', function (value,param) {
      return _.round(value,param)
    }),
    Vue.filter('timeString', function (value) {
        if (!value) {
            return ''
        }
        return moment(value).format('HH:mm:ss')
    }),
    Vue.filter('dayHourMinuteString', function (value) {
        if (!value) {
            return ''
        }
        return moment(value).format('DD-MM HH:mm')
    }),
    Vue.filter('hourMinuteString', function (value) {
        if (!value) {
            return ''
        }
        return moment(value).format('HH:mm')
    }),
    Vue.filter('day', function (value) {
        return moment(value).format('DD/MM/YYYY')
    }),
    Vue.filter('month', function (value) {
        return moment(value).format('MM') + ' - \'' + moment(value).format('YY')
    }),
    Vue.filter('week', function (value) {
        return moment(value).utc().week() + ' - \'' + moment(value).format('YY')
    }),
    Vue.filter('quarter', function (value) {
        return 'Q' +
            moment(value).quarter() +
            ' \'' +
            moment(value).format('YY')
    }),
    Vue.filter('year', function (value) {
        return moment(value).year()
    })
];

export default filters