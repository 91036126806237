<template>
  <router-link :to="{name: this.link}" class="btn btn-primary">
    <i class="fas fa-plus mr-2"/> {{ text }}
  </router-link>
</template>

<script>
  // @vuese
  // @group General/ui
  // Create button
  export default {
    name: 'CreateButton',
    props: {
      //@vuese
      //  route `name` to navigate to
      link: {
        type: String,
        required: true
      },
      //@vuese
      // text to show in button
      text: {
        type: String,
        required:true,
      }
    }
  };
</script>
