/* eslint-disable no-console */
import Vue from 'vue';

import {register} from 'register-service-worker';


if(process.env.NODE_ENV === 'production') {

  register(`./firebase-messaging-sw.js`, {
    ready(registration) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          try{
          if(registration.active && registration.active.scriptURL.indexOf('firebase-messaging-sw.js') == -1) {
            registration.unregister();
          }}catch(e){
            console.log('error removing service worker')
          }

        }
      });
      console.log(
          'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB'
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
      // Vue.swal.fire({
      //   title: 'Updated to  ' + require('./../package').version,
      //   position: 'bottom-end',
      //   backdrop: false,
      //   allowOutsideClick: false,
      //   width: 300,
      //   confirmButtonText: 'Apply update'
      // }).then((result) => {
      //   try {
      //     window.location.reload(true);
      //   } catch (e) {
      //     console.error(e);
      //     window.location.reload(true);
      //   }
      // });
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);

    }
  });

}
