const getters = {
    getMe: state => {
        return {
            ...state.me,
            claims: state.claims
        }
    },

    getClaims: state => state.claims,

    getSelectedWorkflow: state => state.selectedWorkflow,
    getSelectedWorkflowLength: (state, getters) => getters.getSelectedWorkflow == null ? 0 : getters.getSelectedWorkflow.length,
    getRunningJob: state => state.jobRunnning,
    // getOrderlineWorkflowProduct: state => state.orderlineWorkflowProduct,
    getOrderlineWorkflowProduct: state => state.orderlineWorkflowProduct,
    getLocalData: state => state,

};

export default getters