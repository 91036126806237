import Vue from 'vue'
import Toasted from 'vue-toasted';
import i18n from '../i18n'
import '../assets/scss/components/_toasted.scss'
import '../assets/scss/components/_buttons.scss'

Vue.use(Toasted);

let failedOptions = {
  className: 'mToasted',
  position: 'bottom-right',
  type: 'error',
  icon: null,
  duration: 4000,
  iconPack: 'fontawesome'
};

Vue.toasted.register('failedRequest',
  (payload) => {
    if(!payload.message) {
      return 'Oops.. Something Went Wrong..'
    }
    return 'Oops.. ' + payload.message;
  },
  failedOptions
);

let failedFormOptions = {
  className: 'mToasted',
  position: 'bottom-right',
  type: 'error',
  icon: null,
  duration: 4000,
  iconPack: 'fontawesome'
};

Vue.toasted.register('failedFormRequest',
  (payload) => {
    if(!payload.message) {
      return i18n.t('swal.failedFormRequest')
    }
    return payload.message;
  },
  failedFormOptions
);

let successOptions = {
  className: 'mToasted',
  position: 'bottom-right',
  type: 'success',
  duration: 4000,
  icon: null,
  iconPack: 'fontawesome'
};


Vue.toasted.register('successRequest',
  (payload) => {
    if(!payload.message) {
      return 'Succesvol bijgewerkt'
    } else if (payload.message && payload.title) {
        return `<h6 class='toaster-title'>${payload.title}</h6>${payload.message}`
    } else {
        return payload.message;
    }
  },
  successOptions
);

let infoOptions = {
  className: 'mToasted',
  position: 'bottom-right',
  type: 'info',
  duration: 4000,
  icon: {name: 'lightbulb-on'},
  iconPack: 'fontawesome',
  singleton: true
};

Vue.toasted.register('info',
  (payload) => {
    if(!payload.message) {
      return ' '
    }
    return payload.message;
  },
  infoOptions
);