<template>
  <div>

    <div class="dialog">
      <div class="container-login">

        <div class="wrap-login">
          <form @submit.prevent="savePassword" class="login-form validate-form">
            <img class="login-form_logo" src="img/public_img_logo-icon.png" alt="Motivate - Logo">
            <h2>Reset password</h2>
            <div class="validate-alert" v-if="validatePassword">
              {{ this.getValidationErrorPassword }}
            </div>
            <div class="wrap-input validate-input password-input" :class="{'alert-validate': validatePassword}">
						<span class="btn-show-pass" @click="switchVisibility" :class="{active: isActive}">
                            <i class="fas" :class="isActive ? 'fa-eye-slash' : 'fa-eye'"/>
						</span>
              <input label="New password" :type="passwordFieldType" name="password" id="password" v-model="password"
                     class="input"
                     :class="{ 'has-val': password }" @focus="validatePassword = false"
                     autocomplete="current-password">
              <span class="focus-input" data-placeholder="New password"/>
            </div>

            <div class="validate-alert" v-if="validatePasswordConfirm">
              {{ this.getValidationErrorPasswordConfirm }}
            </div>
            <div class="wrap-input validate-input password-input" :class="{'alert-validate': validatePasswordConfirm}">
						<span class="btn-show-pass" @click="switchVisibility" :class="{active: isActive}">
                            <i class="fas" :class="isActive ? 'fa-eye-slash' : 'fa-eye'"/>
						</span>
              <input label="Retype new password" :type="passwordFieldType" name="passwordConfirm" id="passwordConfirm"
                     v-model="passwordConfirm" class="input"
                     :class="{ 'has-val': passwordConfirm }" @focus="validatePasswordConfirm = false">
              <span class="focus-input" data-placeholder="Retype password"/>
            </div>

            <button class="login-form_btn-login" id="submit" type="submit">
              Save password
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

// @vuese
// @group Auth
// This view handles the login of the user.
export default {
  name: 'ResetPassword',
  components: {},
  data() {
    return {
      remember: false,
      isActive: false,
      password: null,
      passwordConfirm: null,
      validatePassword: null,
      validatePasswordConfirm: null,
      passwordFieldType: 'password',
      challenge: null

    };
  },
  computed: {
    getValidationErrorPassword() {
      if (this.validatePassword === 'EMPTY') {
        return 'Password can\'t be empty'
      } else if (this.validatePassword === 'TOO_SHORT') {
        return 'Password should be at least 6 characters'
      } else {
        return null;
      }
    },
    getValidationErrorPasswordConfirm() {
      if (this.validatePasswordConfirm === 'EMPTY') {
        return 'Password can\'t be empty'
      } else if (this.validatePasswordConfirm === 'TOO_SHORT') {
        return 'Password should be at least 6 characters'
      } else if (this.validatePasswordConfirm === 'NO_MATCH') {
        return 'Both passwords are not the same'
      } else {
        return null;
      }
    }
  },
  methods: {
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      this.isActive = !this.isActive;
    },
    async savePassword() {
      if (this.validateInputs()) {
        this.$store.commit('setLoading', true)
        const url = process.env.VUE_APP_CLOUD_API + '/authentication/resetPassword';
        try {
          const oobCode = new URLSearchParams(window.location.search).get('oobCode');
          const apiKey = new URLSearchParams(window.location.search).get('apiKey');
          const uid = new URLSearchParams(window.location.search).get('uid');
          await axios.put(url, {
            oobCode,
            apiKey,
            uid,
            password: this.password
          });
          this.swalToastSuccess('Your password is changed. You can now login again.');
          this.$router.push('/login');
        } catch (e) {
          if (e.response.data.result === 'OPERATION_NOT_ALLOWED') {
            this.swalError('You are not allowed to change your password.');
          } else if (e.response.data.result === 'EXPIRED_OOB_CODE') {
            this.swalError('Your reset password mail has expired. Please request a new one.');
          } else if (e.response.data.result === 'INVALID_OOB_CODE') {
            this.swalError('Your reset password mail is not valid. Please request a new one.');
          } else {
            this.swalError('An unknown error occurred.');
          }
          this.$router.push('/login');

        }
        this.$store.commit('setLoading', false)
      }
    },
    validateInputs() {
      if (!this.password) {
        this.validatePassword = 'EMPTY'
        return false;
      } else if (this.password.length < 6) {
        this.validatePassword = 'TOO_SHORT'
        return false;
      } else if (!this.passwordConfirm) {
        this.validatePassword = null;
        this.validatePasswordConfirm = 'EMPTY'
        return false;
      } else if (this.passwordConfirm.length < 6) {
        this.validatePassword = null;
        this.validatePasswordConfirm = 'TOO_SHORT'
        return false;
      } else if (this.passwordConfirm !== this.password) {
        this.validatePassword = null;
        this.validatePasswordConfirm = 'NO_MATCH'
        return false;
      } else {
        this.validatePassword = null;
        this.validatePasswordConfirm = null;
        return true;
      }
    }
  }
};
</script>


<style lang="scss" scoped>
/***********************************
* DEFAULT RESET
***********************************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box
}

body, html {
  font-family: 'Open Sans', sans-serif;
  height: 100%;
  background-color: #e4e4e4 !important;
}

a {
  font-size: 0.875rem;
  line-height: 1.7;
  color: #999999;
  margin: 0;
  transition: all .4s;
  -webkit-transition: all .4s;
  -o-transition: all .4s;
  -moz-transition: all .4s
}

p {
  font-size: 0.875rem;
  line-height: 1.7;;
  margin: 0
}

input {
  outline: 0;
  border: none
}

button {
  outline: 0 !important;
  border: none;
  background: 0 0
}

button:hover {
  cursor: pointer
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #555555 !important;
}

.dialog {
  .loader {
    position: absolute;
    bottom: 0;
    height: 3px;
    left: 0;
    right: 0;
    width: 100%;
    overflow: hidden
  }

  .loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: #2980b9;
    animation: loading 1s linear infinite
  }

  .screen {
    z-index: 100;
    right: 0;
    opacity: 0;
    cursor: wait
  }
}

.toasted {
  color: white;
  white-space: nowrap;

  &.error {
    background-color: #f00000;
  }

  &.warning {
    background-color: #f46b36;
  }

  &.success {
    background-color: #00ff00;
  }
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%
  }
  50% {
    width: 30%
  }
  70% {
    width: 70%
  }
  80% {
    left: 50%
  }
  95% {
    left: 120%
  }
  to {
    left: 100%
  }

}

/***********************************
* LOGIN FORM
***********************************/
.dialog {
  width: 100%;
  margin: 0 auto;
}

.container-login {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f2f2f2;
}

.wrap-login {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  padding: 0 55px 33px 55px;

  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
}

/***********************************
 * FORM
 ***********************************/
.login-form {
  width: 100%;

  .login-form_logo {
    display: block;
    margin: -60px auto 50px;
    width: 120px;
  }

  .wrap-input {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;

    &.password-input .alert-validate i {
      display: none;
    }

    .input {
      font-size: 0.938rem;
      color: #555555;
      line-height: 1.2;

      display: block;
      width: 100%;
      height: 45px;
      background: transparent;
      padding: 0 5px;

      // Hide X and Eye for edge
      &::-ms-clear,
      &::-ms-reveal {
        display: none;
      }
    }

    .btn-show-pass {
      font-size: 0.938rem;
      color: #999999;

      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      align-items: center;
      position: absolute;
      height: 100%;
      top: 0;
      right: 0;
      padding-right: 5px;
      cursor: pointer;

      &:hover,
      &.active i {
        color: #00c9ff;
      }
    }

    .focus-input {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;

      &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 0;
        height: 2px;

        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;

        background: #00c9ff;
        background: -webkit-linear-gradient(left, #92fe9d, #00c9ff);
        background: -o-linear-gradient(left, #92fe9d, #00c9ff);
        background: -moz-linear-gradient(left, #92fe9d, #00c9ff);
        background: linear-gradient(left, #92fe9d, #00c9ff);
      }

      &::after {
        font-size: 0.938rem;
        color: #999999;
        line-height: 1.2;

        content: attr(data-placeholder);
        display: block;
        width: 100%;
        position: absolute;
        top: 16px;
        left: 0px;
        padding-left: 5px;

        -webkit-transition: all 0.4s;
        -o-transition: all 0.4s;
        -moz-transition: all 0.4s;
        transition: all 0.4s;
      }
    }
  }

  .wrap-remember {
    margin-bottom: 37px;
  }

  .button {
    display: block;
    width: 100%;
    font-weight: 600;
    padding: 15px;
    border-radius: 50px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 1px;

    background: #00c9ff;
    background-size: 210% 210%;
    background-position: 100% 0;
    border: 0;
    transition: all .15s ease;

    &:hover {
      background-position: 0 100%;
      transition: .3s ease-in-out;
    }
  }

  .login-form_btn-login {
    @extend .button;
    margin: 15px 0 0px;
    background-image: linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);
    background-image: -moz-linear-gradient(to bottom left, #00f2c3, #0098f0, #00f2c3);

  }

  #password-forgotten {
    color: gray;
    text-align: center;
    width: 100%;
    margin-top: 15px;
    display: block;
    text-decoration: underline;
  }

  #password-forgotten:hover {
    color: #696969;
  }

  #password-forgotten:focus {
    color: #434343;
  }

  .input-checkbox {
    display: none;
  }

  .input-checkbox:checked + .label-checkbox::before {
    color: #00c9ff;
  }

  .label-checkbox {
    font-size: 0.813rem;
    color: #999999;
    line-height: 1.4;
    display: block;
    position: relative;
    padding-left: 26px;
    cursor: pointer;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Pro";
      font-size: 0.813rem;
      color: transparent;
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      background: #fff;
      border: 1px solid #e6e6e6;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .forgot-password {
    text-align: center;

    a {
      text-decoration: none;

      &:hover {
        color: #00c9ff;
      }
    }
  }
}

.input:focus + .focus-input::before {
  width: 100%;
}

.input:focus + .focus-input::after {
  top: -15px;
}

/* IE 10 + 11 SPECIFIC CSS */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .input + .focus-input::after {
    top: -15px !important;
  }
}

.input:-webkit-autofill + .focus-input::after {
  top: -15px;
}

.has-val.input + .focus-input::after {
  top: -15px;
}

.input:-webkit-autofill + .focus-input::after,
.has-val.input + .focus-input::before {
  width: 100%;
}

/***********************************
* RESPONSIVE
***********************************/
@media (max-width: 576px) {
  .wrap-login {
    padding: 0 15px 120px 15px;
  }
}

.alert-validate::after {
  content: "\f06a";
  font-family: "Font Awesome 5 Pro";
  font-size: 1.125rem;
  color: #c80000;

  display: block;
  position: absolute;
  background-color: #fff;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 5px;
}

.validate-alert {
  margin-bottom: 3rem;
  font-size: 1.4rem;
  color: #c80000;
  border: 1px solid #c80000;
  padding: 0.5rem;
  text-align: center;
}

h2 {
  color: #00c9ff;
  text-align: center;
  margin-bottom: 3rem;
}

</style>