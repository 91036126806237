<template>
  <router-link :to="{name: this.link}" class="btn btn-secondary">
    <i class="fas fa-long-arrow-left mr-2"/> {{$t('ui.back')}}
  </router-link>
</template>

<script>
  // @vuese
  // @group General/ui
  // Backbutton
  export default {
    name: 'BackButton',
    props: {
      // @vuese
      // route `name` to redirect back to
      link: {
        required: true,
        type: String
      }
    }
  };
</script>
