const getters = {
    getTrackAndTraceConfig: (state, getters, rootState, rootGetters) => {
        let companyId = rootGetters['getCompanyId'];
        if (!companyId){
            companyId = rootGetters['users/getClaims'].companyId;
        }
        return _.find(_.toArray(state.trackAndTrace), { companyId: companyId, language: rootGetters['getLocale']});
    },
};

export default getters;