import * as  Users from './functions/users';
import * as Auth from './functions/auth'
import * as TrackAndTrace from './functions/trackAndTrace'

export default class FirebaseFunctions {
  constructor(firebaseFunctions) {
    this.functions = firebaseFunctions.app().functions('europe-west1');
  }
  copyCollection(from, to) {
    return this.functions.httpsCallable('callablesRoot_copyCollection')({fromCollection: from, toCollection: to});
  }
  deleteCollection(data){
    return this.functions.httpsCallable('callablesRoot_deleteCollectionForCompany')(data);
  }
  Users(funcCall, data) {
    return Users[funcCall](this.functions)(data);
  }
  TrackAndTrace(funcCall, data) {
    return TrackAndTrace[funcCall](this.functions)(data);
  }
  Auth(funcCall, data) {
    return Auth[funcCall](this.functions)(data);
  }
}

