import store from './../../store'


const isAuthorized = (router) => {


  router.beforeEach((to, from, next) => {
      $('.modal').modal('hide'); // closes all active pop ups
      $('.modal-backdrop').remove(); // removes the grey overlay.
      store.state.showMenu = false;

      // callback url, retrieves values from url and sets them to store
      if(!store.state.oAuthToken && to.path !== '/callback') {
         next()
      } else {
        if(to.path == '/callback') {

          var urlParams = getParameters(window.location.hash.substr(1));
          if(urlParams.access_token) {
            store.commit('setOauthToken', urlParams.access_token)
          }
          next('/')
        }
        next()
      }
    }
  )
};

export default isAuthorized;

function getParameters(url) {
  //Params obj
  var params = {};
  //To lowercase
  //To array
  url = url.split('&');

  //Iterate over url parameters array
  var length = url.length;
  for (var i = 0; i < length; i++) {
    //Create prop
    var prop = url[i].slice(0, url[i].search('='));
    //Create Val
    var value = url[i].slice(url[i].search('=')).replace('=', '');
    //Params New Attr
    params[prop] = value;
  }

  return params;
}
