<template>
  <div>
    <router-view/>

  </div>
</template>

<script>
import {LoadingScreen} from 'motivate-framework-components';
import MotivateLogoWrapper from "./components/trackAndTrace/MotivateLogoWrapper.vue";


export default {
  name: 'App',
  components: {
    LoadingScreen,MotivateLogoWrapper

  },
  data() {
    return {};
  },

  methods: {
    claims() {
      const claims = this.$FirebaseAuth.getClaims();
      return claims !== undefined ? claims : null;
    },
    // @vuese
    // Checks if the user is authenticated
    // If user is authenticated
    // sets user to store
    async checkUserAuthenticated() {

      let user = await this.$FirebaseAuth.getCurrentUser();
      if (user) {
        this.$store.commit('setLoginLoading', false)
        await this.$store.dispatch('userData/setUserId');
        this.$store.commit('users/setMe', user);
      } else {
        if (_.includes(window.location.pathname.toLowerCase(), '/admin')) {
          this.$router.push('/login');
        } else {
          return true;
        }
      }
    }
  },
  computed: {
    isOnline() {
      return window.navigator.onLine;
    }
  },
  watch: {},
  async created() {
    try {
      this.$store.commit('setTime', moment());
      setInterval(() => {
        this.$store.commit('setTime', moment());
      }, 1000);
    } catch (e) {
      console.error(e)
    }
  },
  created() {
    $("html").css("font-size", "11px");
  }
}
</script>

<style lang="scss">

@import "src/assets/scss/mobile/dataTablesMobileFixes";

html {
  font-size: 12px;
}

body{
  color: black;
}

/deep/ .form-control {
  color: black !important;
}

/deep/ .theme-light .form-control {
  color: black !important;
}


/deep/ .popover {
  z-index: 9999999 !important;
  font-size: 1rem;
}

h5,h1,h2,h3,h4,h6 {
  color: black !important;
}
</style>