import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import firestore from './firestore'

export default {
  namespaced: true,
  ...firestore,
  state,
  getters,
  mutations,
  actions
}