export default {
  methods: {
    swalUnsavedChanges() {
      return this.$swal({
        title: this.$root.$t('swal.unsaved.areyousure'),
        text: this.$root.$t('swal.unsaved.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$root.$t('swal.unsaved.confirm'),
        cancelButtonText: this.$root.$t('ui.cancel'),
        reverseButtons: true
      })
    },
    swalDelete(value) {
      return this.$swal({
        title: this.$root.$t('swal.delete.areyousure'),
        html: this.$root.$t('swal.delete.remove') + ' <strong>' + value + '</strong>? ' + this.$root.$t('swal.delete.undone'),
        icon: 'error',
        customClass: 'm-swal-delete',
        confirmButtonText: this.$root.$t('ui.delete'),
        showCancelButton: true,
        cancelButtonText: this.$root.$t('ui.cancel'),
        reverseButtons: true
      })
    },
    swalToastSuccess(message) {
      return this.$swal({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'success',
        title: message
      })
    },
    swalError(message) {
      return this.$swal({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'error',
        title: message
      })
    },
    swalToastInfo(message) {
      return this.$swal({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000,
        icon: 'info',
        title: message
      })
    },
  }
};
