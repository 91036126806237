<template>
  <div class="motivate-logo-wrapper">
    <span class="powered-by-text" @click="openWebsite()">Powered by</span> <img @click="openWebsite()" class="motivate-logo" src="@/assets/Logo-2022-Motivate.png"/>
  </div>
</template>

<script>
export default {
  name: "MotivateLogoWrapper",
  methods: {
    openWebsite() {
      window.open('https://www.motivate.nl', '_blank')
    }
  }
}
</script>

<style scoped>

.motivate-logo {
  height: 19.36px;
  cursor: pointer;
}

.powered-by-text {
  cursor: pointer;

}

.motivate-logo-wrapper {
  text-align: right;
  padding: 12px;
  font-size: 0.7rem;
  background-color: #F8F9FA;
}
</style>