import Vue from 'vue';



import VueSweetalert2 from 'vue-sweetalert2'


Vue.use(VueSweetalert2);

import VueFileAgent from 'vue-file-agent';
import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css';
Vue.use(VueFileAgent);
import UniqueId from '../plugins/uid';
Vue.use(UniqueId);
