import Vue from 'vue';
import store from 'vuex'

const roleAcces = async (router) => {
    router.beforeEach(async (to, from, next) => {
        let claims;
        let allowed;

        if (Vue.prototype.$FirebaseAuth) {
            claims = await Vue.prototype.$FirebaseAuth.getUserClaims().catch(error => console.log(error));
        }

        if (!claims) {
            next();
        }

        if (to.meta.pageConfigKey) {
            if(claims.root) {
                next();
            }
            if (!router.app.$store.getters['company/getPages']) {
                await router.app.$store.dispatch('company/setPathVars', {companyId: claims.companyId})
                await router.app.$store.dispatch('company/openDBChannel')

            }
            let hasAccess = router.app.$store.getters['company/getPages'][to.meta.pageConfigKey]
             if(!hasAccess){
                next('/')
            }
            allowed = hasAccess
        }
        if (to.meta.hasAccess) {
            to.meta.hasAccess.forEach(role => {
                if (allowed != true) {
                    allowed = claims[role];
                }
            });
            if (allowed) {
                next();
            } else {
                next(from.path);
            }
        } else {
            next();
        }
    });
};

export default roleAcces;
