import Vue from 'vue'
import routes from './routes'
import Router from 'vue-router'
import roleAcces from './middleware/roleAcces'
import isAuthorized from './middleware/isAuthorized'


Vue.use(Router);


const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
});

isAuthorized(router);
roleAcces(router);

export default router
