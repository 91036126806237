const mutations = {
  setMyCompanyMocker(state, payload) {
    state.me.company = payload;
  },
  setMe(state, payload) {
    // let user = checkUserRole(payload)
     if(payload) {
      payload.id = payload.uid;
    }
    state.me = payload;
  },
  setClaims(state,payload){
    state.claims = payload
  },
  setUsers(state, payload) {
    state.users = payload;
  },
  selectedWorkflow(state, payload) {
    state.selectedWorkflow = payload;
  },
  setJobWorkflowProduct(state, payload) {
    state.orderWorkflowProduct = payload;
  },
  setJobRunning(state, payload) {
    state.order = {...state.jobRunning, id: payload.id, uid: payload.uid};
  }
};

export default mutations;


