const firestore = {
  firestorePath: '/jobs',
  firestoreRefType: 'collection',
  moduleName: 'jobs',
  statePropName: 'jobs',
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%',
      productionStart: '%convertTimestamp%',
      productionEnd: '%convertTimestamp%',
    }
  },
  sync: {
    debounceTimerMs: 1,
  preventInitialDocInsertion: true,
    guard: ['created_by', 'updated_by']
  }
};
export default firestore;