const firestore = {
  firestorePath: 'trackAndTrace',
  firestoreRefType: 'collection',
  moduleName: 'trackAndTrace',
  statePropName: 'trackAndTrace',
  namespaced: true,
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%',
      updated_at: '%convertTimestamp%',
    },
  },
  sync: {
    guard: ['created_by', 'updated_by'],
    preventInitialDocInsertion: true
  }
}
export default firestore