import {AdminRole, ROOT} from './middleware/roles';

// This needs to be moved to NONE async
const Home = () => import(/* webpackChunkName: "home" */ '../views/Home.vue');

const TrackAndTraceUnauthorized = () => import(/* webpackChunkName: "Insights" */ '../views/unauthorizedViews/TrackAndTraceUnauthorized');

const AdminTrackAndTrace = () => import(/* webpackChunkName: "Admin" */ '../views/admin/trackAndTrace/EditTrackAndTrace');
import Login from './../views/auth/Login.vue';
import ResetPassword from './../views/auth/ResetPassword';

export default [

    {path: '/resetPassword', name: 'resetPassword', component: ResetPassword},
    {
        path: '/admin', name: 'home', component: Home,
        children: [
            {path: '', name: 'admin', component: AdminTrackAndTrace, meta: {hasAccess: AdminRole}}
        ]
    },
    {path: '/login', name: 'login', component: Login},
    {
        path: '/:companyName/:orderNumber/:secretKey', name: 'TrackAndTraceUnauthorized', component: TrackAndTraceUnauthorized,
    },
    {
        path: '/:companyName/:orderNumber', name: 'TrackAndTraceUnauthorized', component: TrackAndTraceUnauthorized,
    },
    {
        path: '/:companyName', name: 'TrackAndTraceUnauthorized', component: TrackAndTraceUnauthorized,
    },
    {
        path: '', name: 'TrackAndTraceUnauthorized', component: TrackAndTraceUnauthorized,
    },
    {path: '/callback', name: 'cb', component: Home},
    {
        path: '*',
        redirect: '/admin'
    }
];
