const CALLABLE_PREFIX = 'callablesRootUsers_';
export const createUser = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'createUser');
};
export const updateUser = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'updateUser');
};
export const deleteUser = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'deleteUser');
};
export const getAllUsers = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'getAllUsers');
};
export const getUserById = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'getUserById');
};


export const getUserPincodeEnabled = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'getUserPincodeEnabled');
};

export const getUserPincodeEnabledUnauthorized = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'getUserPincodeEnabledUnauthorized');
};

export const checkUserPincodeUnauthorized = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'checkUserPincodeUnauthorized');
};


export const checkUserPincode = (functions) => {
  return functions.httpsCallable(CALLABLE_PREFIX + 'checkUserPincode');
};
