import 'motivate-framework/dist/css/motivate.css';
import Vue from 'vue';
import './init/components/components';
import './init/components/frameworkComponents';
import App from './App.vue';
import router from './router/index';
import store from './store';
import motivateColors from './assets/scss/MotivateColors.scss'
// Disabled for now
import './registerServiceWorker';

import './init/mixins';
import './init/functions';
import './init/vueBootstrap';
import './init/filters';
// import {initFireBase} from './init/initFirebase'
import MotivateFirebaseAuth from './init/firebase';
import i18n from './i18n';
import FirebaseFunctions from './init/firebase/firebaseFunctions';


Vue.prototype.$APP_VERSION = process.env.VUE_APP_VERSION;

Vue.prototype.$motivateColors = motivateColors;

window._ = require('lodash');
window.moment = require('moment');
window.momentTz = require('moment-timezone');


require('./filters/filters');
require('./plugins/toaster');


// This part manages the backdrop of modals when there are multiple modals opened over eachother
$(document).on('show.bs.modal', '.modal', function (event) {
  var zIndex = 1040 + (10 * $('.modal:visible').length+1);
  $(this).css('z-index', zIndex);
  setTimeout(function() {
    $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
  }, 100);
});


// import {firestorePlugin} from 'vuefire'
//  HNEED TO CHECK IF THIS WORKS
//
// Vue.use(firestorePlugin);
// initFireBase()
setupFirebase();



async function setupFirebase() {
  Vue.prototype.$FirebaseAuth = await new MotivateFirebaseAuth();
  Vue.prototype.$FUNCTIONS = new FirebaseFunctions(Vue.prototype.$FBClient);


  renderVue();
}

async function renderVue() {

  new Vue({
    router: router,
    store: store,
    i18n,
    render: h => h(App)
  }).$mount('#app');
}

